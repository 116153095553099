<template>
  <v-container fluid style="padding:0!important">

    <!-- top toolbar -->
    <v-toolbar>
      <v-tooltip top open-delay="150">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-if="!base_item.locked" :loading="update_button" v-bind="attrs" v-on="on"
            @click="save_formula_details(false)" text outlined class="red darken-3 mr-2"
            elevation="0">
            <v-icon color="white">mdi-location-exit</v-icon>
          </v-btn>
        </template>
        <span>Save &amp; Exit</span>
      </v-tooltip>

      <v-text-field readonly v-model="base_item.number"
                    hide-details color="blue-grey darken-1"
                    :suffix="unlock_timer"
                    class="font-weight-bold" outlined dense></v-text-field>

      <v-spacer></v-spacer>

      <v-tooltip top open-delay="150">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-shortkey="['ctrl','alt','p']" v-bind="attrs" v-on="on" text outlined class="blue darken-3 ml-2" elevation="0" @click="experiment_print">
            <v-icon color="white">mdi-cloud-print-outline</v-icon>
          </v-btn>
        </template>
        <span>Print</span>
      </v-tooltip>

      <v-tooltip top open-delay="150">
        <template v-slot:activator="{ on, attrs }">
          <v-btn text outlined class="ml-2" elevation="0" v-shortkey="['ctrl','alt','e']" v-bind="attrs" v-on="on" disabled>
            <v-icon>mdi-microsoft-excel</v-icon>
          </v-btn>
        </template>
        <span>Export Formula to Excel</span>
      </v-tooltip>

      <v-tooltip top open-delay="150">
        <template v-slot:activator="{ on, attrs }">
          <v-btn text outlined class="blue darken-3 ml-2" elevation="0" v-shortkey="['ctrl','alt','c']" @click="confirmClone" v-bind="attrs" v-on="on">
            <v-icon color="white">mdi-content-duplicate</v-icon>
          </v-btn>
        </template>
        <span>Clone Formula</span>
      </v-tooltip>

      <v-tooltip top open-delay="150">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-if="!base_item.locked" :loading="update_button" v-bind="attrs" v-on="on"
            @click="save_formula_details(false)" text outlined :class="modified ? 'red' : 'blue'" class="darken-3 ml-2"
            elevation="0">
            <v-icon color="white">mdi-content-save-outline</v-icon>
          </v-btn>
        </template>
        <span>Save Formula</span>
      </v-tooltip>

    </v-toolbar>

    <!-- panels -->
    <v-expansion-panels v-model="panels" accordion multiple focusable :disabled="panel_disabled">

      <!-- formulation panel -->
      <v-expansion-panel key="formulation">
        <v-expansion-panel-header class="text-uppercase font-weight-bold">
          Formulation Details
          <span class="ml-1 caption">- created by {{base_item.first}} {{base_item.last}} on {{base_item.created}}
            <v-icon v-if="base_item.archive_id!=null" class="ml-2 blue--text text--darken-2">mdi-archive-outline</v-icon>
            <v-icon v-if="base_item.isDuplicate" class="ml-2 blue--text text--darken-2">mdi-content-duplicate</v-icon>
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>

          <!-- first row data input -->
          <v-row dense>
            <v-col cols="8">

              <!-- data entry fields -->
              <v-row class="mt-4" dense>
                <v-col cols="8">
                  <v-text-field ref="description" outlined dense :disabled="base_item.locked" spellcheck hide-details
                    v-model="base_item.description" label="Description" placeholder="Formula Description" maxlength="75"
                    counter="75" color="blue">
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field ref="company" :disabled="base_item.locked" outlined dense spellcheck hide-details
                    v-model="base_item.company" label="Company" placeholder="Company" maxlength="35" color="blue"
                    counter="35">
                  </v-text-field>
                </v-col>
              </v-row>

              <!-- brix fields -->
              <v-row dense>
                <v-col cols="6">
                  <v-text-field @click:prepend="get_brix_density" v-model="base_item.brix" maxlength="10" counter="10"
                    :disabled="base_item.locked" :hint="base_item.brix_hint" color="blue" outlined dense hide-details
                    @keydown="validate" label="Brix">
                    <v-icon slot="append" @click="brix_notes()" color="grey">mdi-comment</v-icon>
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="base_item.density" color="blue" label="Density g/L"
                    :loading="base_item.brix_busy" outlined dense :disabled="base_item.locked" @keydown="validate" hide-details
                    :rules="[density_rule]" placeholder="Density g/L" maxlength="10" counter="10">
                    <template v-slot:append>
                      <v-tooltip top open-delay="150">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn @click="get_brix_density" small icon v-bind="attrs" v-on="on">
                            <v-icon>mdi-magnify</v-icon>
                          </v-btn>
                        </template>
                        <span>Brix Database Lookup</span>
                      </v-tooltip>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>

              <!-- tritable -->
              <v-row dense>
                <v-col cols="6">
                  <v-text-field :disabled="base_item.locked" color="blue" v-model="base_item.ph" label="pH" hide-details
                    placeholder="pH" outlined dense maxlength="10" counter="10" required @keydown="validate">
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field :disabled="base_item.locked" color="blue" v-model="base_item.acidity" hide-details
                    label="Titratable Acidity %" outlined dense placeholder="Titratable Acidity" maxlength="10"
                    counter="10" required @keydown="validate"></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="4">
              <v-row class="mt-4" dense>
                <v-col cols="12">
                  <v-select outlined dense :disabled="base_item.locked" color="blue" class="caption" label="Processing" hide-details
                    v-model="processing_rules" :items="processing" @change="save_field_change" :rules="[select_rule]">
                  </v-select>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <v-select outlined color="blue" dense v-model="category_tags" :items="category_items" class="caption" hide-details
                    label="Category" ref="category" :rules="[select_rule]">
                    <template v-slot:selection="{ item }">
                      <v-chip label small  outlined class="green--text">
                        <v-icon left>mdi-label</v-icon>{{ item }}
                      </v-chip>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <!-- added 2/15  -->
              <v-row dense>
                <v-col cols="12">
                  <v-text-field v-model="base_item.recommended_use" :disabled="base_item.locked" label="Recommended Use Level" outlined dense hide-details
                    maxlength="100" counter="100"></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-select outlined v-model="keyword_tags" :items="keyword_items" hide-details class="caption"
                label="Keywords" multiple ref="keywords" :rules="[select_rule]">
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index <= 6" label small outlined class="green--text">
                    <v-icon left small>mdi-label</v-icon>{{ item }}
                  </v-chip>
                  <span v-if="index === 6">(+{{ keyword_tags.length - 1 }} others)</span>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <!-- data table -->
      <v-expansion-panel key="details" @change="hook_sortable_table($event)" class="panel_shrink">
        <v-expansion-panel-header class="text-uppercase font-weight-bold">
          Formulation
        </v-expansion-panel-header>
        <v-expansion-panel-content color="blue-grey lighten-3">
          <!-- main table -->
          <v-data-table id="formulation" :headers="formulation.headers" :items="formulation.items" class="elevation-3"
            :loading="formulation.loading" item-key="dbid" :disable-pagination="true" calculate-widths
            :disable-sort="formulation.custom_sorting" hide-default-footer v-model="formulation.selected"
            :footer-props="{ 'items-per-page-options': [-1] }">
            <!-- table top options row -->
            <template v-slot:top>
              <v-toolbar flat class="grey lighten-3">
                <span class="overline mr-2">Batch Size</span>
                <v-text-field v-model="batch_size" class="shrink orange--text caption" solo dense flat hide-details
                  maxlength="4" @keydown="validate"></v-text-field>
                <v-divider class="mx-4 black" inset vertical></v-divider>

                <!-- adding new formulation type -->
                <v-select v-model="formula_type" placeholder="Formula Type" :items="formula_type_items" solo dense flat
                  hide-details class="shrink-select orange--text mr-2 caption" @change="adjust_formula_type">
                </v-select>
                <!-- reaction event -->
                <v-select hide-details v-if="formula_type===1" v-model="formula_uom" solo dense flat
                  :items="formula_uom_items" @change="adjust_measurement_metrics" placeholder="Unit of Measurement"
                  class="shrink-select orange--text caption">
                </v-select>

                <v-spacer></v-spacer>
                <v-tooltip top open-delay="150" v-if="!base_item.locked">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-if="formulation.custom_sorting" class="mr-2 indigo" text outlined small
                      @click="reset_sort_method" v-bind="attrs" v-on="on">
                      <v-icon class="white--text">mdi-sort-variant-lock</v-icon>
                    </v-btn>
                    <v-btn v-else class="mr-2 blue-grey" text outlined small v-bind="attrs" v-on="on">
                      <v-icon class="white--text">mdi-sort-variant-lock-open</v-icon>
                    </v-btn>
                  </template>
                  <span v-if="formulation.custom_sorting">Custom Sorting</span>
                  <span v-else>System Sorting</span>
                </v-tooltip>
                <v-tooltip top open-delay="150" v-if="!base_item.locked">
                  <!-- add water -->
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn text outlined class="blue-grey" v-shortkey="['ctrl','2']" @shortkey="add_water()" small dark
                      @click="add_water()" :disabled="!is_water" v-bind="attrs" v-on="on">Add Water
                      <v-icon right small>mdi-water</v-icon>
                    </v-btn>
                  </template>
                  <span>CTRL + 2</span>
                </v-tooltip>
                <!-- add a new row -->
                <v-tooltip top open-delay="150" v-if="!base_item.locked">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn text outlined class="blue-grey ml-2" v-shortkey="['ctrl','1']"
                      @shortkey="dialog_search_window()" @click="dialog_search_window()" small
                      :disabled="base_item.density==''" v-bind="attrs" v-on="on"><span class="white--text">Add
                        Item</span>
                      <v-icon color="white" right small>mdi-table-row-plus-before</v-icon>
                    </v-btn>
                  </template>
                  <span>CTRL + 1</span>
                </v-tooltip>
              </v-toolbar>
            </template>
            <!-- table rows -->
            <template v-slot:[`item.item`]="{ item }">
              <!-- draggable handle -->
              <div class="handle ma-0 pa-0">
                <v-icon class="float-left mt-1 green--text green--lighten-2">mdi-drag</v-icon>
              </div>
              <v-text-field :disabled="base_item.locked" v-model="item.item" spellcheck class="caption" maxlength="75"
                counter="75" placeholder="Item" dense outlined hide-details>
                <v-icon v-if="item.flag" slot="append" @click="adjust_water(item)"
                  :color="total_formula_percent <= 100 ? 'blue' : 'red'">mdi-water</v-icon>
              </v-text-field>
            </template>
            <!-- adding new look -->
            <template v-slot:[`item.code`]="{ item }">
              <v-text-field :disabled="base_item.locked" outlined v-model="item.code" class="caption" maxlength="15"
                counter="15" placeholder="Code" dense flat hide-details></v-text-field>
            </template>
            <template v-slot:[`item.glvalue`]="{ item }">
              <v-text-field :disabled="base_item.locked" v-model="item.value" class="caption" maxlength="8" counter="8"
                outlined placeholder="Value" dense flat hide-details @keydown="validate" @blur="apply_padding">
              </v-text-field>
            </template>
            <!-- add comment field somehow -->
            <template v-slot:[`item.rmc`]="{ item }">
              <v-text-field :disabled="base_item.locked" v-model="item.rmc" class="caption" maxlength="8" counter="8"
                outlined placeholder="RMC" dense flat hide-details @keydown="validate">
                <v-icon slot="append" @click="outline_notes(item)" color="grey">mdi-comment</v-icon>
              </v-text-field>
            </template>
            <template v-slot:[`item.batchvalue`]="{ item }">
              <v-text-field v-model="item.glvalue" class="caption" maxlength="8" counter="8" dense hide-details flat reverse
                @keydown="validate" :value="$set(item, 'glvalue', +(item.value * batch_size).toFixed(2))" disabled solo>
              </v-text-field>
            </template>

            <template v-slot:[`item.percentformula`]="{ item }">
              <!-- V-IF -->
              <v-text-field v-if="formula_classification === 0" v-model="item.percentformula" class="caption"
                maxlength="8" counter="8" placeholder="Percent" dense flat hide-details solo reverse
                :value="$set(item, 'percentformula', ((item.value / base_item.density)*100).toFixed(4))" disabled>
              </v-text-field>
              <v-text-field v-else v-model="item.percentformula" class="caption" maxlength="8" counter="8"
                placeholder="Percent" dense flat hide-details solo reverse
                :value="$set(item, 'percentformula', ((item.value / 1000)*100).toFixed(4))" disabled>
              </v-text-field>
            </template>

            <template v-slot:[`item.ciu`]="{ item }">
              <v-text-field disabled class="caption" maxlength="8" counter="8" dense flat hide-details reverse
                :value="$set(item, 'ciu', ((item.rmc / 1000) * item.rmc).toFixed(2))" solo></v-text-field>
            </template>
            <!-- actions -->
            <template v-slot:[`item.action`]="{ item }" v-if="!base_item.locked">
              <v-tooltip top open-delay="150">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="red" @click="delete_item(item)" v-bind="attrs" v-on="on">mdi-close</v-icon>
                </template>
                <span>Delete Item</span>
              </v-tooltip>
            </template>
            <!-- footer -->
            <template v-slot:[`body.append`]>
              <tr class="grey lighten-5 font-weight-bold">
                <td></td>
                <td>Totals</td>

                <!-- V-iF -->
                <td v-if="formula_classification === 0">
                  <span :class="+total_value_column < +base_item.density ? 'green--text' : +total_value_column === +base_item.density ? 'blue--text' : 'red--text'">{{total_value_column}}</span>/{{base_item.density}}
                </td>
                <td v-else-if="formula_classification === 1">
                  <span :class="total_value_column <= 1000 ? 'green--text' : 'red--text'">{{total_value_column}}</span>/1000
                </td>
                <td v-else> </td>

                <td style="text-align:right!important;">{{total_rmc_column}}</td>
                <td style="text-align:right!important;">{{total_value_in_batch}}</td>
                <td style="text-align:right!important;">
                  <span
                    :class="total_formula_percent < 100 ? 'blue--text' : total_formula_percent == 100 ? 'green--text' : 'red--text'">{{total_formula_percent}}</span>
                </td>
                <td style="text-align:right!important;">$ {{total_cui_column}}</td>
                <td></td>
              </tr>
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel key="mixing" class="panel_shrink">
        <v-expansion-panel-header class="text-uppercase font-weight-bold">
          MIXING INSTRUCTIONS&nbsp;<span class="caption blue--text">{{base_item.number}} -
            {{base_item.description}}</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="blue-grey lighten-3">
          <div id="mixing">
            <editor @onBlur="blur" @onFocus="focus" v-model="base_item.mixing" id="mixing"
              api-key="ttbthscmul66taoxff1xnpgtjhl7rdn8cwvdyo9mefawcskb" :init="{
                   height: 500,
                   menubar: true,
                   resize: false,
                   plugins: [ 'advlist autolink lists link image charmap print preview anchor', 'searchreplace visualblocks', 'insertdatetime table paste code help wordcount spellchecker' ],
                   toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
                  }" />
          </div>
          <v-progress-linear :indeterminate="base_item.mixing_busy" color="blue"></v-progress-linear>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel key="notes" class="panel_shrink">
        <v-expansion-panel-header class="text-uppercase font-weight-bold">
          APPLICATION NOTES&nbsp;<span class="caption blue--text">{{base_item.number}} -
            {{base_item.description}}</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="blue-grey lighten-3">
          <v-row>
            <v-col md="12">
              <div id="notes">
                <editor @onBlur="blur" v-model="base_item.notes" id="notes" @onFocus="focus"
                  api-key="ttbthscmul66taoxff1xnpgtjhl7rdn8cwvdyo9mefawcskb" :init="{
                   height: 500,
                   menubar: true,
                   resize: false,
                   plugins: [ 'advlist autolink lists link image charmap print preview anchor', 'searchreplace visualblocks', 'insertdatetime table paste code help wordcount spellchecker' ],
                   toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
                  }" />
              </div>
            </v-col>
          </v-row>
          <v-progress-linear :indeterminate="base_item.mixing_busy" color="blue"></v-progress-linear>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel key="properties" class="panel_shrink">
        <v-expansion-panel-header class="text-uppercase font-weight-bold">
          ADDITIONAL PROPERTIES&nbsp;<span class="caption blue--text">{{base_item.number}} -
            {{base_item.description}}</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="blue-grey lighten-3">
          <v-row>
            <v-col md="12">
              <div id="properties">
                <editor @onBlur="blur" v-model="base_item.properties" id="properties" @onFocus="focus"
                  api-key="ttbthscmul66taoxff1xnpgtjhl7rdn8cwvdyo9mefawcskb" :init="{
                   height: 500,
                   menubar: true,
                   resize: false,
                   plugins: [ 'advlist autolink lists link image charmap print preview anchor', 'searchreplace visualblocks', 'insertdatetime table paste code help wordcount spellchecker' ],
                   toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
                  }" />
              </div>
            </v-col>
          </v-row>
          <v-progress-linear :indeterminate="base_item.mixing_busy" color="blue"></v-progress-linear>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel key="archive" :disabled="base_item.archive_id===null" @click="archive_expansion_click">
        <v-expansion-panel-header class="text-uppercase font-weight-bold" disable-icon-rotate>
          ARCHIVE FORMULA BUILD
          <template v-slot:actions>
            <v-icon color="primary">mdi-information-outline</v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="blue-grey lighten-3">
          <v-simple-table class="mt-4" dense color="white" light>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left font-weight-bold">Description</th>
                  <th class="text-left">GPL</th>
                  <th class="text-left">Brix Value</th>
                  <th class="text-left">Brix Contributed</th>
                  <th class="text-left">PCtWW</th>
                  <th class="text-left">GPKG</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in archive_items" :key="item.name">
                  <td>{{ item.desc }}</td>
                  <td>{{ item.gpl }}</td>
                  <td>{{ item.brix }}</td>
                  <td>{{ item.brixcont }}</td>
                  <td>{{ item.pctww }}</td>
                  <td>{{ item.gpkg }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-btn :disabled="archive_copied_items" class="mt-2" small block color="success" elevation="0"
            @click="copy_archive_items">Copy Archive to Formulations Table</v-btn>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    
    <!-- dialog -->
    <v-dialog v-model="search.dialog_active" max-width="550">
      <v-card flat>
        <v-subheader class="body-1 blue--text">Item Core Lookup <v-divider inset></v-divider></v-subheader>
        <v-card-text class="pt-2">
          <v-text-field v-model="search.search" label="PK/R# Expression" hide-details clearable
            v-on:keyup.enter="componentQuery" ref="expression" outlined class="upper"></v-text-field>
          <v-btn small block color="blue" class="mt-2 mb-2" @click="add_item_without_code">No Item Code
          </v-btn>
          <v-text-field v-model="search.filter" label="Additional Results Filter" dense hide-details clearable outlined>
          </v-text-field>
        </v-card-text>
      </v-card>
      <v-card max-height="400" flat>
        <v-card-text>
          <v-data-table id="search_table" :headers="search.headers" v-model="search.selected" :items="search.items"
            :dense="search.dense" :search="search.filter" item-key="value" @dblclick:row="component_select"
            hide-default-footer :loading="search.busy" :footer-props="{ 'items-per-page-options': [-1] }">
          </v-data-table>
        </v-card-text>
        <v-spacer></v-spacer>
        <v-card-actions class="grey lighten-4"> <span class="caption">Press ESC to close window</span> </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- confirmation dialog -->
    <ConfirmDlg ref="confirm" />
    <!-- print dialog -->
    <PrintOptionsDialog ref="printOptionsDialog" :data="print_object" />

    <!-- notes dialog -->
    <v-dialog v-model="formula_note.modal" max-width="550" persistent @keydown.esc="formula_note.modal = false">
      <v-card outlined>
        <v-subheader class="body-1 blue--text">Line Item Notes <v-divider inset></v-divider>
        </v-subheader>
        <v-card-text>
          <v-textarea v-model="formula_note.note" outlined no-resize rows="6" max-length="900" persistent-hint
            :hint="formula_note.hint" counter="900"></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-btn outlined small color="error" text @click="formula_note.modal = false">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="save_formula_note">Save</v-btn>
        </v-card-actions>
        <v-card-actions class="grey lighten-4">
          <span class="caption">Press ESC to close window</span>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- notes dialog -->
    <v-dialog v-model="brix_note.modal" max-width="550" persistent @keydown.esc="base_item.brix_comment=old_brix_note;brix_note.modal=false">
      <v-card outlined>
        <v-subheader class="body-1 blue--text">Brix/Temperature <v-divider inset></v-divider>
        </v-subheader>
        <v-card-text>
          <v-textarea v-model="base_item.brix_comment" outlined no-resize rows="6" max-length="900" counter="900"></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-btn small outlined color="error" text @click="base_item.brix_comment=old_brix_note;brix_note.modal=false">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="save_brix_note">Save</v-btn>
        </v-card-actions>
        <v-card-actions class="grey lighten-4">
          <span class="caption">Press ESC to close window</span>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */

import axios from 'axios'
import Sortable from 'sortablejs'
import Editor from '@tinymce/tinymce-vue'
import { helperMixin } from '../mixins/helper'
import { EventBus } from '../components/EventBus.js'
import formatDistance from 'date-fns/formatDistance'
import { __category_items, __keywords} from './data/arrays/categories'

const WATER_ROW = 1

const VALUE_COLUMN = 2
const BATCH_COLUMN = 4
const RMC_COLUMN = 3
const CUI_COLUMN = 6

const GRAMS_FORMULA = 0
const PERCENT_BASIS_FORMULA = 1
const MIXED_UNITS = 2

export default {
  name: "FormulationEditor",
  mixins: [ helperMixin ],
  components: {
    'editor': Editor,
    ConfirmDlg: () => import('./dlgConfirm'),
    PrintOptionsDialog: () => import('./dlgPrintOptions')
  },
  async beforeRouteLeave(to, from, next) {
    if (this.modified) {
      console.log(`this.modified ${this.modified}`)
      const msg = `You have unsaved changes, leave this page?`
      if (await this.$refs.confirm.open("Confirmation", msg)) {
        clearInterval(this.idle_timer)
        this.clear_lock_flag()
        next()
      }
    } else {
      clearInterval(this.idle_timer)
      this.clear_lock_flag()
      next()
    }
  },
  data: () => ({
    pdf_filename: '',
    brix_note: {
      modal: false,
      note: '',
    },
    formula_note: {
      note: '',
      hint: '',
      modal: false,
      item: {}
    },
    processing: [
      'Hot Fill', 'Cold Fill', 'Cold Fill (Preserved)', 'Tunnel', 'UHT', 'UHT (Shelf Stable)', 'HTST', 'Retort', 'Batch Past', 'Velcorin', 'Dry Blend', 'Other'
    ],
    archive_items: [],
    archive_copied_items: false,
    formula_classification: null,
    formula_type: '',
    formula_type_items: [ { text: 'Grams/Liter', value: 0 }, { text: 'Percent Basis', value: 1 } ],
    formula_uom: '',
    formula_uom_items: [ { text: 'Weight/W', value: 0 }, { text: 'Volume/V', value: 1 }, { text: 'Mixed Units', value: 2 }],
    edit_object: {},
    measurement_metrics: '',
    search: {
      single_select: true,
      selected: [],
      dialog_active: false,
      headers: [
        { text: 'Item', align: "left", sortable: true, value: 'text', class: "font-weight-bold", divider: true },
        { text: 'Code', align: "left", sortable: true, value: 'value', class: "font-weight-bold" },
        { text: 'Cost', align: "left", sortable: true, value: 'cost', class: "font-weight-bold" },
        { text: 'Date', align: "left", sortable: true, value: 'last' },
      ],
      items: [],
      search: '',
      filter: '',
      dense: true,
      busy: false,
    },
    update_button: false,
    saving_formula: false,
    batch_size: 1,
    created_by: '',
    new_formulation: false,
    query: '',
    results: [],
    resultselect: null,
    searchbusy: false,
    selectedRow: 0,
    panels: [0, 1],
    panel_disabled: false,
    base_item: {
      locked: false,
      mixing: '',
      mixing_busy: false,
      mixing_focus_copy: '',
      properties: '',
      properties_busy: false,
      properties_focus_copy: '',
      notes: '',
      notes_busy: false,
      notes_focus_copy: '',
      // tinyMCE editor windows end
      created: '',
      acidity: '',
      ph: '',
      density: '',
      brix: '',
      brix_busy: false,
      brix_hint: 'Enter Custom Brix',
      description: '',
      number: '',
      recommended_use: '',
      brix_comment: '',
    },
    formulation: {
      selected: [],
      // any ajustment to this table MUST be reflected in the CONST declarations to align column numbers up
      // until the code updates to use indexOf by adding a search
      headers: [
        { text: "Item", align: "left", sortable: false, value: "item", width: "30%" },
        { text: "Code", align: "left", sortable: false, value: "code", width: "10%" },
        { text: "Value*", align: "left", sortable: true, value: "glvalue", width: "10%" },
        // { text: "UOM", align: "left", sortable: true, value: "", width: "10%" },
        { text: "RMC", align: "left", sortable: false, value: "rmc", width: "10%", divider: true },
        { text: "Batch*", sortable: false, value: "batchvalue", width: "8%", align: 'right' },
        { text: "Formula %", sortable: false, value: "percentformula", width: "8%", align: 'right' },
        { text: '$CIU/L', sortable: false, value: "ciu", width: "8%", divider: true, align: 'right' },
        { text: '', align: 'center', sortable: false, value: "action", width: '6%' },
      ],
      items: [],
      loading: false,
      search: '',
      dense: true,
      custom_sorting: false,
    },
    idx: 0,
    hook_assigned: false,
    loading: [],
    hint: [],
    modified: false,
    idle_timer: null,
    idle_timer_progress: 100,
    panels_movement_hook: null,
    category_tags: [],
    category_items: __category_items,
    keyword_tags: [],
    keyword_items: __keywords,
    processing_rules: [],
    old_brix_note: '',
    auto_exit_timer: null,
    print_object: {},
    unlock_timer: null,
  }),
  watch: {
    category_tags() { this.$refs.category.setMenuIndex(0) },
    keyword_tags() { this.$refs.keywords.setMenuIndex(0) },
  },
  mounted: function () {

    this.idx = this.$router.history.current.params.id ?? 0
    this.formulation.loading = true
    
    let current = new Date()
    this.auto_exit_timer = current.setMinutes(current.getMinutes() + 20)
    this.idle_timer_to_string()

    this.adjust_measurement_metrics()
    this.load_formula()
    this.hook_sortable_table()
    this.set_panel_mouse_hook()
    this.init_timer()
  },
  computed: {
    is_water () {
      if(this.formulation.items.length === 0) return false
      return this.formulation.items.findIndex(r => r.item === 'WATER') === -1 ? true : false
    },
    select_rule () {
      return (v) => {
        if(typeof(v) === "object") { return v.length === 0 ? false : true }
        return (v === '' || v === null) ? false : true
      }
    },
    density_rule() {
      return (v) => {
        if (this.formula_type != null) {
          if (this.formula_type === GRAMS_FORMULA && (v === '' || v === null)) return 'Required for Grams/Liter'
        }
        if (v === '' || v === null) return false
        if (!isNaN(parseFloat(v)) && v < 1600) return true
        return 'High Density?'
      }
    },
    brix_table() {
      let bt = []
      for (var i = 0; i < 80; i += 0.1000) {
        bt.push(i.toFixed(2))
      }
      bt.push(100)
      return bt
    },
    total_cui_column() {
      return this.formulation.items.map(r => +r.ciu).reduce((p, c) => p + c, 0).toFixed(4)
    },
    total_rmc_column() {
      return this.formulation.items.map(r => +r.rmc).reduce((p, c) => p + c, 0).toFixed(4)
    },
    total_value_column() {
      return this.formulation.items.map(r => +r.value).reduce((p, c) => p + c, 0).toFixed(4)
    },
    total_value_in_batch() {
      return this.formulation.items.map(r => r.glvalue).reduce((p, c) => p + c, 0).toFixed(4)
    },
    total_formula_percent() {
      return this.formulation.items.map(r => +r.percentformula).reduce((p, c) => p + c, 0).toFixed(2)
    },
  },
  methods: {
    experiment_print () {
      let items = []
      this.formulation.items.forEach(v => { items.push({desc: v.item, column1: v.code, column2: v.glvalue}) })
      this.print_object = {
        description: this.base_item.description,
        customer: this.base_item.company,
        ref: this.base_item.number,
        brix: this.base_item.brix,
        sum_column: 2,
        headers: [ 'Description', 'Item Number', 'Value' ],
        items,
        mixing: this.base_item.mixing,
        application: this.base_item.notes,
        additional: this.base_item.properties,
        batch_size: this.batch_size
      }
      this.$refs.printOptionsDialog.open()
    },
    exit () {
      this.clear_panel_mouse_hook()
      clearInterval(this.idle_timer)
      this.idle_timer = null
      console.log('::timerdisable')
      this.$router.push("/formulations").catch(() => {})
    },
    print () {
      this.$refs.printOptionsDialog.open()
    },
    save_formula_note () {
      this.formula_note.modal = !this.formula_note.modal
      this.formulation.items[this.formula_note.item].formula_note = this.formula_note.note
      this.formula_note.item = null
      this.formula_note.note = ''
    },
    outline_notes (item) {
      this.formula_note.hint = item.item // show the item being worked on
      this.formula_note.note = item.formula_note // copy the current note
      this.formula_note.item = item.dbid // store the item index so we can write it back out
      this.formula_note.modal = !this.formula_note.modal
    },
    clear_lock_flag() {
      axios.get(`${this.$store.getters.NODEURL}/formula/editing/release/${this.idx}`, {
          withCredentials: true
        })
        .then(() => {
          EventBus.$emit('notify', {
            message: `Formula Unlocked`
          })
        })
        .catch(() => {
          EventBus.$emit('error', {
            message: `Failed to Unlock Formula for Editing - 66`
          })
        })
        .finally(() => {
          this.clear_panel_mouse_hook()
        })
    },
    set_panel_mouse_hook() {
      this.panels_movement_hook = document.querySelector('.v-expansion-panels--accordion')
      this.panels_movement_hook.onmousemove = this.init_timer
      this.panels_movement_hook.onmousedown = this.init_timer
      this.panels_movement_hook.onclick = this.init_timer
      this.panels_movement_hook.onscroll = this.init_timer
      this.panels_movement_hook.onkeypress = this.init_timer
      console.log('::panel_hook(mouse)')
    },
    clear_panel_mouse_hook() {
      this.panels_movement_hook = null
      console.log('::panel_unhook(mouse)')
    },
    idle_timer_to_string () {
      this.unlock_timer = !this.auto_exit_timer ? '' : 'unlocks ' + formatDistance(new Date(this.auto_exit_timer), new Date(), { addSuffix: true })
    },
    init_timer() {
      this.idle_timer_progress = 100
      let current = new Date()
      this.auto_exit_timer = current.setMinutes(current.getMinutes() + 20)
      this.idle_timer_to_string()
      clearInterval(this.idle_timer)
      this.idle_timer = setInterval(function () {
        console.log('no motion, decreasing time remaining')
        this.idle_timer_progress -= 5 // 20 minute timer
        this.idle_timer_to_string()
        if (this.idle_timer_progress <= 0) {
          console.log('no motion in twenty minutes, saving and exiting')
          this.clear_panel_mouse_hook()
          this.force_save_logout()
        }
      }.bind(this), 60 * 1000)
    },
    // on a user timeout, force save any pending changes and send the user back to the formulation page
    force_save_logout() {
      clearInterval(this.idle_timer)
      this.save_formula_details(true)
    },
    hook_sortable_table() {
      const _self = this
      if (!this.hook_assigned) {
        setTimeout(() => {
          console.log('::sortable_hooked(drag)')
          let table = document.querySelector('.v-data-table tbody')
          Sortable.create(table, {
            handle: ".handle",
            onEnd({
              newIndex,
              oldIndex
            }) {
              let p = _self.formulation.items[oldIndex]
              let v = _self.formulation.items[newIndex]
              _self.$set(_self.formulation.items, oldIndex, v)
              _self.$set(_self.formulation.items, newIndex, p)
              _self.formulation.custom_sorting = true
            }
          })
          this.hook_assigned = !this.hook_assigned
        }, 0)
      }
    },
    reset_sort_method() {
      this.formulation.custom_sorting = false
      //this.formulation.headers[VALUE_COLUMN].sortable = true
    },
    copy_archive_items() {
      if (this.archive_copied_items === false) {
        this.formulation.loading = true
        this.archive_items.forEach(r => {
          if (r.desc.toLowerCase() != 'water')
            this.formulation.items.push({
              item: r.desc,
              code: 'n/a',
              rmc: 0,
              ciu: 0,
              value: 0
            })
        })
        this.archive_copied_items = true
        setTimeout(() => {
          this.formulation.loading = false
        }, 1000)
      }
    },
    archive_expansion_click(e) {
      if (this.archive_items.length === 0) {
        if (!e.target.classList.contains('v-expansion-panel-header--active')) {
          let archive_id = this.base_item.archive_id
          axios.post(`${this.$store.getters.NODEURL}/formula/archive`, {
              archive_id
            }, {
              withCredentials: true
            })
            .then((result) => {
              this.archive_items = result.data.data
            })
            .catch(() => {
              EventBus.$emit('error', {
                message: `Save Failed - 44`
              })
            })
            .finally(() => {})
        }
      }
    },
    clone_formula() {
      axios.post(`${this.$store.getters.NODEURL}/formula/clone/${this.idx}`, { withCredentials: true })
           .then((res) => {
            console.log(JSON.stringify(res.data, null, 2))
            this.$router.push({ path: `/formulations/editor/${res.data.item.idx}`})
            setTimeout(()=>{ this.$router.go() },500)
           })
           .catch((err) => {
             console.log(err)
             EventBus.$emit('error', { message: 'Something went wrong, please contact Greg with error number 88.' })
           })
    },
    async confirmClone() {
      const msg = `Remember to save any changes prior to cloning - are you sure you want to clone this formula now?`
      if (await this.$refs.confirm.open("Formula Cloning", msg)) {
        this.clone_formula()
      }
    },
    adjust_formula_type() {
      switch (this.formula_type) {
        case 0:
          this.formula_classification = GRAMS_FORMULA
          this.formula_uom = null
          this.formulation.headers[VALUE_COLUMN].text = 'Value/L'
          this.formulation.headers[BATCH_COLUMN].text = 'Batch/L'
          this.formulation.headers[CUI_COLUMN].text = '$CIU/L'
          this.formulation.headers[RMC_COLUMN].text = 'RMC/L'
          break;
        case 1:
          this.formula_classification = PERCENT_BASIS_FORMULA
          this.formulation.headers[VALUE_COLUMN].text = 'Value *'
          this.formulation.headers[BATCH_COLUMN].text = 'Batch *'
          this.formulation.headers[CUI_COLUMN].text = '$CIU *'
          this.formulation.headers[RMC_COLUMN].text = 'RMC *'
          break;
        case 2:
          this.formula_classification = MIXED_UNITS
          this.formulation.headers[VALUE_COLUMN].text = 'Value'
          this.formulation.headers[BATCH_COLUMN].text = 'Batch'
          this.formulation.headers[CUI_COLUMN].text = 'Batch'
          this.formulation.headers[RMC_COLUMN].text = 'RMC'
          break;
      }
    },
    adjust_measurement_metrics() {
      switch (this.formula_uom) {
        case 0:
          this.formulation.headers[VALUE_COLUMN].text = 'Value (g/KG)'
          this.formulation.headers[BATCH_COLUMN].text = 'Batch (g/KG)'
          this.formulation.headers[CUI_COLUMN].text = '$CIU/KG'
          this.formulation.headers[RMC_COLUMN].text = 'RMC/KG'
          break;
        case 1:
          this.formulation.headers[VALUE_COLUMN].text = 'Value (ml/L)'
          this.formulation.headers[BATCH_COLUMN].text = 'Batch (ml/L)'
          this.formulation.headers[CUI_COLUMN].text = '$CIU/L'
          this.formulation.headers[RMC_COLUMN].text = 'RMC/KG'
          break;
      }
    },
    add_item_without_code() {
      this.formulation.items.push({
        item: this.search.search.toUpperCase(),
        code: 'n/a',
        value: 0,
        rmc: 0,
        ciu: 0,
        formula_note: ''
      })
      this.search.filter = ''
      this.search.search = ''
    },
    dialog_search_window() {
      this.search.dialog_active = true
      setTimeout(() => {
        this.$refs.expression.focus()
      }, 250)
    },
    validate(e) {
      if ([8, 9, 37, 39, 190, 110].includes(e.which)) return // backspace, tab, copy, paste, period
      if (!new RegExp('^[0-9]$').test(e.key)) e.preventDefault()
    },
    focus(evt) {
      // add feature to not call "save" if the user hasn't changed the text
      switch (evt.target.id) {
        case 'mixing':
          this.base_item.mixing_focus_copy = this.base_item.mixing
          break;
        case 'notes':
          this.base_item.properties_focus_copy = this.base_item.properties
          break;
        case 'properties':
          this.base_item.notes_focus_copy = this.base_item.notes
          break;
      }
    },
    blur(evt) {
      let payload
      switch (evt.target.id) {
        case 'mixing':
          payload = this.base_item.mixing;
          this.base_item.mixing_busy = true
          break;
        case 'notes':
          payload = this.base_item.notes;
          break;
        case 'properties':
          payload = this.base_item.properties;
          break;
      }
      let item = {
        idx: this.idx,
        payload: payload,
        type: evt.target.id
      }
      axios.post(`${this.$store.getters.NODEURL}/formula/note`, {
          item
        }, {
          withCredentials: true
        })
        .then(() => {
          EventBus.$emit('notify', {
            message: `Saved`
          })
        })
        .catch((err) => {
          console.warn(err)
          EventBus.$emit('error', {
            message: `Failed to Save - 28`
          })
        })
        .finally(() => {
          setTimeout(() => {
            this.base_item.mixing_busy = false
          }, 750)
        })
    },
    adjust_water() {
      for (var i = 0; i < this.formulation.items.length; ++i) {
        let p = this.formula_classification === GRAMS_FORMULA ? this.base_item.density : 1000
        if (this.formulation.items[i].flag === WATER_ROW) {
          this.formulation.items[i].value = 0
          this.formulation.items[i].value = (p - +this.total_value_column).toFixed(4)
          return
        }
      }
    },
    add_water() {
      if (!this.formulation.items.filter(r => r.flag == WATER_ROW).length) {
        let p = this.formula_classification === GRAMS_FORMULA ? this.base_item.density : 1000
        this.formulation.items.push({
          flag: WATER_ROW,
          item: 'WATER',
          code: '',
          rmc: 0,
          ciu: 0,
          value: (p - +this.total_value_column).toFixed(3)
        })
      } else {
        EventBus.$emit('error', {
          message: `Formula already contains a water row.`
        })
      }
    },
    delete_item(item) {
      this.formulation.items.splice(this.formulation.items.indexOf(item), 1)
    },
    load_formula() {
      axios.get(`${this.$store.getters.NODEURL}/formulations/${this.idx}`, {
          query: this.query
        }, {
          withCredentials: true
        })
        .then(response => {
          let d = response.data

          this.created_by = `${d.results.first} ${d.results.last}`
          // user provided formula type
          this.formula_type = d.results.formula_type
          this.formula_uom = d.results.formula_type === PERCENT_BASIS_FORMULA ? d.results.unit_of_measurement : null
          // set the batch size
          this.batch_size = d.results.batch_size
          // set the overall formula style
          this.formula_classification = d.results.formula_type

          this.category_tags = d.results.category ?? ''
          this.keyword_tags = d.results.subcategory?.split(',') ?? []

          /* eslint-disable no-empty */
          try {
            delete d.results.category
            delete d.results.subcategory
            // delete d.results.specific
          } catch { }
          /* eslint-enable no-empty */

          Object.assign(this.base_item, d.results)

          try {
            let parsed = JSON.parse(d.items.formula_items)
            /* let i = 0
            let __items = parsed.map(r => {
              r.formula_note = (i++).toString()
              return r
            }) */
            this.formulation.items = parsed
            this.formulation.custom_sorting = d.items.custom_sort
            let dbid = 0
            // pad all decimals with a leading 0
            this.formulation.items.forEach(e => {
              if (e.value < 1) {
                e.value = +e.value.toString().padStart(2, "0")
              }
              e.dbid = dbid++
            })
            // if were not using a custom sort, sort the table
            if (!this.formulation.custom_sorting) {
              this.formulation.items.sort((a, b) => +a.value > +b.value ? -1 : 1)
            }
          } catch {
            this.formulation.items = []
          }
        })
        .catch(e => {
          console.log(e)
          // relase the lock 
          EventBus.$emit('error', {
            message: `Failed to load formula - Error 55`
          })
          this.panels = []
          this.results = []
          this.panel_disabled = true
        })
        .finally(() => {
          this.formulation.loading = false
          this.adjust_formula_type()
          this.adjust_measurement_metrics()
        })
    },
    componentQuery() {
      this.search.busy = true
      this.search.filter = ''
      axios.post(`${this.$store.getters.NODEURL}/formula/com/query`, {
          query: this.search.search
        }, {
          withCredentials: true
        })
        .then(response => {
          this.search.items = response.data.results
        })
        .catch(e => {
          console.error(e)
          EventBus.$emit('error', {
            message: `Search Failed - 47`
          })
          this.results = []
        })
        .finally(() => {
          this.search.busy = false
        })
    },
    component_select(e, i) {
      this.formulation.items.push({
        item: i.item.text,
        code: i.item.value,
        rmc: i.item.cost,
        ciu: 0,
        value: 0
      })
      this.search.filter = ''
      this.search.search = ''
    },
    save_formula_details(redirect_after) {

      if (this.base_item.locked) return

      let blob = JSON.stringify(this.formulation.items) // turn part table into a blob object

      let state = {
        description: this.base_item.description,
        company: this.base_item.company,
        category: this.category_tags,
        keywords: this.keyword_tags,
        processing: this.processing_rules,
        brix: this.base_item.brix,
        density: this.base_item.density,
        ph: this.base_item.ph,
        acidity: this.base_item.acidity,
        batch_size: this.batch_size,
        brix_comment: this.base_item.brix_comment,
        recommended_use: this.base_item.recommended_use
      }

      axios.post(`${this.$store.getters.NODEURL}/formula/details`, {
          blob,
          custom_sort: this.formulation.custom_sorting,
          total: this.formulation.items.length,
          number: this.base_item.number,
          user: this.$store.getters.userPID,
          idx: this.idx,
          batch_size: this.batch_size,
          formula_type: this.formula_type,
          unit_of_measurement: this.formula_uom,
          state
        }, {
          withCredentials: true
        })
        .then(() => {
          EventBus.$emit('notify', {
            message: `Formula Details Saved`
          })
        })
        .catch(() => {
          EventBus.$emit('notify', {
            message: `Save Failed - 44`
          })
        })
        .finally(() => {
          if (redirect_after) {
            clearInterval(this.idle_timer)
            this.$router.push("/formulations").catch(() => {})
          } else {
            setTimeout(() => {
              this.saving_formula = false
            }, 800)
            this.modified = false
          }
        })
    },
    get_brix_density() {
      this.base_item.brix_busy = true
      axios.post(`${this.$store.getters.NODEURL}/formula/brix`, {
          brix: this.base_item.brix
        }, {
          withCredentials: true
        })
        .then((result) => {
          this.base_item.density = result.data.data.gpl
          this.base_item.brix_hint = 'Using Database Density'
        })
        .catch(() => {
          this.base_item.brix_hint = 'Using Your Custom Density'
        })
        .finally(() => {
          this.base_item.brix_busy = false
        })
    },
    apply_padding(e) {
      if (e.target.value < 1.0) {
        e.target.value = +e.target.value.toString().padStart(3, "0")
      }
    },
    save_field_change() {
      this.modified = true
    },
    brix_notes () {
      this.old_brix_note = this.base_item.brix_comment
      this.brix_note.modal = true
    },
    save_brix_note () {
      this.brix_note.modal = false
      this.modified = true
    }
  }
}
</script>

<style scoped>
 >>> .theme--light.v-text-field--filled > .v-input__control > .v-input__slot { background: #fafafa!important; }
 .v-card { display: flex !important; flex-direction: column; }
 .v-card__text { flex-grow: 1; overflow: auto; }
 .v-expansion-panel--active > .v-expansion-panel-header { min-height:32px!important }
 .shrink { max-width: 100px!important; }
 .shrink-select { max-width: 150px!important; }
 .panel_shrink >>> .v-expansion-panel-content__wrap { padding: 8px!important;}
</style>

<style>
 .v-data-table > .v-data-table__wrapper > table > tbody > tr > td { padding: 0 6px!important; }
 .upper input { text-transform: uppercase; }
 .handle { cursor: move !important; cursor: -webkit-grabbing !important;}
</style>